@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth;
}

body {
  @apply font-plus-jakarta-sans;
}

nav {
  @apply bg-brand-dark-blue py-3 pl-2 pr-6 lg:py-[18px] lg:px-2.5 w-full;
}

main {
  @apply pt-[83px] lg:pt-[100px];
}

footer {
  @apply bg-brand-dark-blue pt-10 pb-5 text-white;
}

h1 {
  @apply text-[40px] font-bold;
}

h2 {
  @apply font-roboto font-bold text-[40px]; 
}

h3 {
  @apply text-2xl;
}

p {
  @apply text-base lg:text-xl;
}

label {
  @apply text-base;
}

input, textarea, select {
  @apply p-3 rounded border border-brand-dark-blue transition-all duration-300 focus:outline-none;
}

button {
  @apply px-8 py-4 bg-brand-dark-blue font-roboto text-lg font-bold rounded text-white;
}

.error {
  @apply border-red-500 bg-red-500 bg-opacity-5;
}

.hero {
  @apply bg-brand-dark-blue;
}

.nav {
  @apply lg:gap-10 flex flex-col lg:flex-row lg:items-center;
}

.nav a {
  @apply text-white block relative px-4 py-5 lg:p-0 rounded-[2px];
}

.nav a::after {
  @apply absolute bottom-0 left-0 w-full bg-white opacity-0;
  content: '';
  height: 0.1em;
  transition: opacity 300ms, transform 300ms;
}

.nav a:hover::after {
  @apply opacity-100;
  transform: translate3d(0, 0.2em, 0);
}

.card {
  @apply w-full h-full bg-center !bg-cover transition-all bg-no-repeat;
  background: url('../../assets/images/video-cover.jpg');
}

.card-parent {
  @apply overflow-hidden;
}

.card-parent:hover .card,
.card-parent:focus .card {
  transform: scale(1.2);
}